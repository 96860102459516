import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './core/auth.guard';
import { Four04Component } from './pages/404/four04.component';
import {InfoLayoutComponent} from './layouts/info/info-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'user',
                loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'agent',
                loadChildren: () => import('./pages/agent/agent.module').then(m => m.AgentModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'master',
                loadChildren: () => import('./pages/master/master.module').then(m => m.MasterModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'searchable',
                loadChildren: () => import('./pages/searchable/searchable.module').then(m => m.SearchableModule),
                canActivate: [AuthGuard],
            }
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
            }
        ]
    }, {
        path: '',
        component: InfoLayoutComponent,
        children: [
            {
                path: 'search',
                loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
            }
        ]
    },
    {
        path: '**',
        component: Four04Component
    }
];
