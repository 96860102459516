import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { AuthorModel } from './model/author.model';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { UserModel } from './model/user/userModel';
import {UserRoleIdModel} from './model/userRoleId.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  STORAGE_KEY = 'local_user';
  user: Observable<UserModel>;

  constructor(
      private afAuth: AngularFireAuth,
      private afs: AngularFirestore,
      private router: Router,
      @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {

    //// Get auth data, then get firestore user document || null
    this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            return this.afs.doc(`users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        })
    );
  }

  public getUser() {
    return this.storage.get(this.STORAGE_KEY);
  }

  public storeUserOnLocal(userData: string): void {
    this.storage.set(this.STORAGE_KEY, userData);
  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
        .then((credential) => {
          this.updateUserData(credential.user);
        });
  }


  private updateUserData(user) {
    // Sets user data to firestore on login

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data: AuthorModel = {
      uid: user.uid,
      name: user.name,
    };

    return userRef.set(data, { merge: true });

  }

  clearLocalStorage() {
    this.storage.clear();
  }

  signOut() {
    this.clearLocalStorage();
    this.storage.remove(this.STORAGE_KEY);
    this.afAuth.signOut().then(() => {
      window.location.href = '/login';
      // this.router.navigate(['/login']);
    });
  }
}
