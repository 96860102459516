import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {AuthService} from '../core/auth.service';
import {UserModel} from '../core/model/user/userModel';
import {Helpers} from '../app.helper';

declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    permission?: string;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    permission?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [{
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'dashboard'
    },
    {
        path: '/user',
        title: 'User',
        type: 'sub',
        icontype: 'people_alt',
        collapse: 'user',
        children: [
            {path: 'list', title: 'List', ab: 'L', permission: 'user-view'}
        ],
        permission: 'user-view'
    },
    {
        path: '/agent',
        title: 'Agent',
        type: 'sub',
        icontype: 'people',
        collapse: 'agent',
        children: [
            {path: 'list', title: 'List', ab: 'L', permission: 'agent-view'},
            {path: 'closing', title: 'Closing', ab: 'C', permission: 'agent-closing-view'}
        ]
    },
    {
        path: '/master',
        title: 'Master',
        type: 'sub',
        icontype: 'home_work',
        collapse: 'master',
        children: [
            {path: 'listing', title: 'Listing', ab: 'L', permission: 'listing-view'},
            {path: 'pap', title: 'PAP', ab: 'P', permission: 'pap-view'},
            {path: 'closing', title: 'Closing', ab: 'C', permission: 'closing-view'},
            {path: 'project', title: 'Project', ab: 'P', permission: 'project-view'},
        ]
    }, /*{
        path: '/searchable',
        title: 'Data Pencarian',
        type: 'sub',
        icontype: 'home_work',
        collapse: 'searchable',
        children: [
            {path: 'listing', title: 'List', ab: 'L'},
            {path: 'map', title: 'Map', ab: 'M'}
        ]
    }*/
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    constructor(private helper: Helpers) {}

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => {
            let allowMainMenu = true;
            let children: ChildrenItems[] = [];
            if (menuItem.permission !== undefined) {
                allowMainMenu = this.helper.checkAllow(menuItem.permission);
            }
            if (allowMainMenu) {
                if (menuItem.children === undefined) {
                    return menuItem;
                } else {
                    children = menuItem.children.filter(childrenItem => {
                        let allowChildren = true;
                        if (childrenItem.permission !== undefined) {
                            allowChildren = this.helper.checkAllow(childrenItem.permission);
                        }
                        if (allowChildren) {
                            return childrenItem;
                        }
                    });
                    menuItem.children = children;
                    return menuItem;
                }
            }
        });
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
