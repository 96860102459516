import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {UserModel} from './core/model/user/userModel';
import {AuthService} from './core/auth.service';

@Injectable()
export class Helpers {
    userLocal: UserModel;
    constructor(private auth: AuthService) {
        if (this.auth.getUser() !== undefined) {
            this.userLocal = JSON.parse(this.auth.getUser()) as UserModel;
        }
    }

    public nFormatter(num, digits) {
        const si = [
            { value: 1, symbol: '' },
            { value: 1E3, symbol: 'Rb' },
            { value: 1E6, symbol: 'Jt' },
            { value: 1E9, symbol: 'M' },
            { value: 1E12, symbol: 'T' },
            { value: 1E15, symbol: 'P' },
            { value: 1E18, symbol: 'E' }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
    }

    public capitalize(string) {
        return string.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    public numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    public prepareFilterParams(request: any, includeEmpty?: boolean) {
        let filter = new HttpParams();
        Object.keys(request).forEach(function (key, index) {
            if (Array.isArray(request[key])) {
                request[key].forEach( v => {
                    filter = filter.append(key + '[]', v);
                });
            } else {
                if (includeEmpty) {
                    if (request[key] === null || request[key] === '') {
                        filter = filter.append(key, '');
                    } else {
                        filter = filter.append(key, request[key]);
                    }
                } else {
                    if (request[key] !== null && request[key] !== '') {
                        filter = filter.append(key, request[key]);
                    }
                }
            }
        });

        return filter;
    }

    checkAllow(requiredPermission) {
        if (this.userLocal.role_id === 1) {
            return true;
        } else {
            return this.userLocal.permissions.includes(requiredPermission);
        }
    }
}
