import { Component, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var bodymovin: any;

@Component({
  selector: 'app-logout',
  templateUrl: './four04.component.html',
  styleUrls: ['./four04.component.css']
})
export class Four04Component implements OnInit {

  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId,
  ) {
  }

  ngOnInit() {
    // this.playLoading();
  }

  playLoading() {
    const nativeElement: HTMLElement = this.elementRef.nativeElement;
    const loadingContainer = nativeElement.getElementsByClassName('loading-animation').item(0);

    if (isPlatformBrowser(this.platformId)) {
      bodymovin.loadAnimation({
        container: loadingContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'assets/img/animation/404-animation.json'
      });
    }
  }

}
