// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  aws_bucket: 'petarumahbucket.s3.amazonaws.com',
  hash_salt: '9387kgj0gkeu4id85h7s0j2jg7f5k3d',
  gmaps_key: 'AIzaSyDskTQ4sGb6AYX7VEQlSyQAO9sdDSp-jT8',
  firebase: {
    apiKey: 'AIzaSyDskTQ4sGb6AYX7VEQlSyQAO9sdDSp-jT8',
    authDomain: 'peta-rumah-141103.firebaseapp.com',
    databaseURL: 'https://peta-rumah-141103.firebaseio.com',
    projectId: 'peta-rumah-141103',
    storageBucket: 'peta-rumah-141103.appspot.com',
    messagingSenderId: '32698222565',
    appId: '1:32698222565:web:e9c9a72ebad380936a510c',
    measurementId: 'G-D8SW9VS3CQ'
  }
};
