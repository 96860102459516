import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService} from './auth.service';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { log } from 'util';
import { UserModel } from './model/user/userModel';
import { user } from 'firebase-functions/lib/providers/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserRoleIdModel} from './model/userRoleId.model';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private afs: AngularFirestore) {}

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> {

    const that = this;
    return this.auth.user.pipe(
      take(1),
      map(user => !!user),
      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigate(['/login']);
        } else {
          if (this.auth.getUser() === null || this.auth.getUser() === undefined) {
            this.auth.user.subscribe(  (userServer) => {
              const nUser = Object.assign({}, userServer);
              this.afs.collection('user_role_id').doc(nUser.role_id.toString()).get().subscribe((doc) => {
                if (doc.exists) {
                  const role = doc.data() as UserRoleIdModel;
                  if (role.priority >= 4) {
                    this.RedirectWithNotification('Access denied', '/login');
                  } else {
                    nUser.permissions = Object.assign([], role.permission);
                  }
                  const now = new Date();
                  now.setDate(now.getDate() + 1);
                  nUser.login_timeout = now.getTime();
                  that.auth.storeUserOnLocal(JSON.stringify(nUser));
                } else {
                  this.RedirectWithNotification('Access denied', '/login');
                }
              });
            });
          } else {
            const userLocal = JSON.parse(this.auth.getUser()) as UserModel;
            const now = new Date().getTime();
            if (userLocal.login_timeout === undefined || userLocal.login_timeout < now) {
              this.RedirectWithNotification('Login Expired', '/login');
            }
            if (userLocal.role_id === undefined || userLocal.role_id >= 4) {
              this.RedirectWithNotification('Access denied', '/login');
            }
          }
        }
      })
    );
  }

  private RedirectWithNotification(message, navigateTo) {
    $.notify({
      icon: 'notifications',
      message: message,
    }, {
      type: 'danger',
      timer: 3000,
      placement: {
        from: 'top',
        align: 'right'
      }
    });
    if (navigateTo === '/login') {
      this.auth.signOut();
    }
    this.router.navigate([navigateTo]);
  }
}
